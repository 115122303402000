// spacings
.mb--30{
  margin-bottom: 30px!important;
}
.mb-d--30{
  margin-bottom: -30px!important;
}
// Section Spcaing

@media (min-width: 992px){
  .mb-lg-0, .my-lg-0 {
      margin-bottom: 0 !important;
  }
}
.col-xs-6 {
  @include mobile {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
}

// .section-padding{
//   &-both{
//     @include section-padding-both(100px);
//   }
//   &-top{
//     @include section-padding-top(100px);
//   }
//   &-bottom{
//     @include section-padding-bottom(100px);
//   }
// }

.section-title{
  // text-align: center;
  &.white{
    .title{
      color: #fff;
    }
    p{
      color: rgba(255, 255, 255, 0.7);
    }
  }
  h2, .title{
    color: $theme-primary-heading;
    font-family: $theme-primary-font;
    font-weight: 700;
    letter-spacing: 0px;
    font-size: 40px;
    line-height: 54px;
    margin-bottom: 15px;
    @include mobile {
      font-size: 30px;
      line-height: 40px;
    }
    @include mobile-lg {
      font-size: 40px;
      line-height: 50px;
    }
    @include desktops {
      font-size: 50px;
      line-height: 60px;
    }
  }
  p{
    color: $theme-primary-text;
    font-family: $theme-primary-font;
    font-size: 17px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 30px;
  }
}

.title {
  color: $theme-primary-heading;

}
p {
  color: $theme-primary-text;
}


@keyframes spin-reverse {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(-360deg);
  }
  }
  @keyframes spin {
  from {
    transform:rotate(0deg) translate(-50%);
    transform-origin: center;
  }
  to {
    transform:rotate(360deg) translate(-50%);
  }
}

.mb-d-30 {
  margin-bottom: -30px;
}


.z-index-1{
  z-index: 1;
}



.position-lg-absolute{
  position: static!important;
  @include desktops{
    position: absolute!important;
  }
}

.bg-black-2{
   background-color: $theme-black-color-2;
}
.bg-gray{
  background-color: #F2F2F3;
}
.bg-athens-gray{
  background-color: #F6F6F8;
}
.bg-black-squeeze{
  background-color: #FCFDFE;
}
.bg-indigo{
  background-color: #0BCDB3;
}
.border-whisper-top{
  border-top: 1px solid #EAE9F2;
}

.text--primary{
  color: $l8-secondary-color;
}

